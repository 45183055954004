import React, { useEffect, useState } from "react"
import "./style.scss"
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Pagination,
  Row,
  Skeleton,
  Space,
  Spin,
} from "antd"
import moment from "moment"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { getListNews } from "../../services/news"
import { navigate } from "gatsby"

const ListNewsAll = () => {
  const [loading, setLoading] = useState(false)
  const [listNews, setListNews] = useState(null)
  const [total, setTotal] = useState(0)
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })
  const fetchListNews = async () => {
    setLoading(true)
    const { response } = await getListNews({
      limit: tableParams.pagination.pageSize,
      offer:
        (tableParams.pagination.current - 1) * tableParams.pagination.pageSize,
      orderDESC: ["schedule"],
    })
    if (response.status == 200) {
      setLoading(false)
      console.log("responseresponse", response)

      setListNews(response.data.news)
      setTotal(response.data.count)
    }
  }
  useEffect(() => {
    fetchListNews()
  }, [JSON.stringify(tableParams)])

  return (
    <div >
      <div className={"Content"}>
        <Spin tip="Loading..." spinning={loading}>
          {loading && <Skeleton />}
        </Spin>
        {listNews && (
          <div style={{ width: "100%", minHeight: 400 }}>
            <Space direction="vertical" style={{ width: "100%" }} size={0}>
              {listNews.map((item, index) => (
                <div
                  key={index}
                  className={`${"news_content"} ${
                    !item.isReaded ? "not_read" : ""
                  }`}
                >
                  <Row
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/news/detail/${item.id}`)
                    }}
                  >
                    <Col xs={7} md={5} className={"notifyDate"}>
                      {moment(item.schedule).format("YYYY/MM/DD")}
                    </Col>
                    <Col xs={17} md={19} className={"notifyText"}>
                      {item.title}
                    </Col>
                  </Row>
                  <Divider style={{ margin: 0, marginTop: 10 }} />
                  <div
                    style={{
                      borderRadius: "3px",
                      background:
                        (item.news_type == "news" && "#F0F5FF") ||
                        (item.news_type == "notification" && "#FCEFDB") ||
                        "unset",
                      textAlign: "center",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      padding: "0 10px",
                      color: "#333",
                    }}
                  >
                    {item.news_type == "news" && "ニュース"}
                    {item.news_type == "notification" && "お知らせ"}
                  </div>
                </div>
              ))}
            </Space>
            <div style={{ marginTop: "2rem", textAlign: "end" }}>
              <Pagination
                total={total}
                current={tableParams.pagination.current}
                pageSize={tableParams.pagination.pageSize}
                onChange={(page, pageSize) => {
                  setTableParams({
                    pagination: {
                      current: page,
                      pageSize: 10,
                    },
                  })
                }}
                itemRender={(_, type, originalElement) => {
                  console.log("hihihi1", _)
                  console.log("hihihitype", type)
                  console.log("originalElement", originalElement)
                  if (type === "prev") {
                    return (
                      <>
                        <Space>
                          <Button style={{ borderRadius: "2px" }}>
                            <a>
                              <DoubleLeftOutlined
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: "1rem",
                                }}
                              />
                            </a>
                          </Button>
                          <Button style={{ borderRadius: "2px" }}>
                            <a className="font-semibold">前のページ</a>
                          </Button>
                        </Space>
                      </>
                    )
                  }

                  if (type === "next") {
                    return (
                      <>
                        <Space>
                          <Button style={{ borderRadius: "2px" }}>
                            <a className="font-semibold">次のページ</a>
                          </Button>
                          <Button style={{ borderRadius: "2px" }}>
                            <a>
                              <DoubleRightOutlined
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: "1rem",
                                }}
                              />
                            </a>
                          </Button>
                        </Space>
                      </>
                    )
                  }

                  return originalElement
                }}
                showSizeChanger={false}
                showTotal={(total, range) => (
                  <div>
                    {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                    {total}件)
                  </div>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ListNewsAll
